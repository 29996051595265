import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import menu from './menu/reducers'
import settings from './settings/reducers'
import { LoginReducer } from '../pages/user/login/index'

import Org from '../pages/user/register/redux/reducers'
import ForgotPassword from '../pages/user/forgot/redux/reducers'
import HomeMenu from '../components/LayoutComponents/TopBar/HomeMenu/Redux/reducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    // user,
    menu,
    settings,
    user: LoginReducer,

    forgotPassword: ForgotPassword,

    homeMenu: HomeMenu,
  })
