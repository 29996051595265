import React, { Component } from 'react'

import qs from 'qs'
import { Input, Button, Form, Card } from 'antd';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { empLoginData, verifyEmail } from './redux/method'
import styles from './style.module.scss'

// @connect(({ user }) => ({ user }))

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
        password: "",
      },
    };
  }
  // onSubmit = event => {
  //   event.preventDefault()
  //   const { form, dispatch } = this.props
  //   form.validateFields((error, values) => {
  //     if (!error) {
  //       dispatch({
  //         type: 'user/LOGIN',
  //         payload: values,
  //       })
  //     }
  //   })
  // }

  handleChangeInput = (e) => {
    let fields = this.state.fields
    fields[e.target.id] = e.target.value;
    this.setState({
      fields
    })
  }

  // componentDidMount() {
  //   var query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  //   if (query && query.token) {
  //     this.props.verifyEmail(query.token)
  //   }
  // }

  render() {


    return (
      <div className="w-full mx-4 mt-16 lg:w-2/3 xl:w-1/2 ">
        <Helmet title="Login" />
        <Card title={<div className="text-center">EONbt</div>}>
          <h4 className="mb-8 text-3xl font-bold text-center text-primary">Download EONbt app</h4>
          <div className="flex">
            <a style={{ width: "46%" }} href={window.SITE_CONFIG.GOOGLE_PLAY_LINK}>
              <img className="rounded-lg shadow-lg" src="/resources/images/googlePlatBtn.jpg" />
            </a>
            <span style={{ width: "8%" }}></span>
            <a style={{ width: "46%" }} href={window.SITE_CONFIG.APP_STORE_LINK}>
              <img className="rounded-lg shadow-lg" src="/resources/images/appStoreBtn.jpg" />
            </a>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return { login: state.user }
}

export default connect(mapStateToProps, { empLoginData, verifyEmail })(Login)

