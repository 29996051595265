import actionType from './actions'
import axios from '../../../../utils/axios'
import { Button, notification } from 'antd';
import { history } from "../../../../App"
import { dataURLtoFile } from "../../../../utils/helpers"

export const empLoginData = (data) => async (dispatch, getState) => {
    data.isDashboard = true
    return;
    axios.post(`/users/login`, data)
        .then(response => {
            const res = response.data.response.data;
            // const token = res.token;

            // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(res))
            // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, token)

            // dispatch({ type: actionType.EMP_LOGIN, payload: { ...res, authorized: true } })
            // dispatch({ type: "menu/GET_DATA" })

            // dispatch(dispatchUser(res))
        })
        .catch(err => {
            const options = {
                message: 'Login failed',
                description: err.message
            };
            if (err.response)
                options.description = err.response.data.error[0].msg
            notification.error(options);
        })
}

export const dispatchUser = (userData) => (dispatch) => {
    if (userData.token) {
        window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(userData))
        window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, userData.token)

        dispatch({ type: actionType.EMP_LOGIN, payload: { ...userData, authorized: true } })
        dispatch({ type: "menu/GET_DATA" })
    } else
        dispatch({ type: actionType.EMP_LOGIN, payload: { authorized: false } })
}


export const verifyEmail = (token) => async (dispatch) => {
    axios.get(`/user/verifyEmail?token=` + token)
        .then(response => {

            notification.success({
                message: 'Email verify successfully',
                description: "You can login now."
            });

        })
        .catch(err => {

            const options = {
                message: 'Email verification fail',
                description: err.message
            };
            if (err.response)
                options.description = err.response.data.error[0].msg
            notification.error(options);
        })
}




// export const getByToken = (token, nextPage) => async (dispatch) => {
//     axios.get(`/users/me`, { headers: { Authorization: "bearer " + token } })
//         .then(response => {
//             const res = response.data.response.data;

//             dispatch(dispatchUser(res))

//             // const token = res.token;

//             // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(res))
//             // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, token)

//             // dispatch({ type: actionType.EMP_LOGIN, payload: { ...res, authorized: true } })
//             // dispatch({ type: "menu/GET_DATA" })
//             if (nextPage)
//                 history.push(nextPage)
//         })
//         .catch(err => {



//             const options = {
//                 message: 'Login failed',
//                 description: err.message
//             };
//             if (err.response)
//                 options.description = err.response.data.error[0].msg
//             notification.error(options);
//         })
// }

export const checkLoginData = () => (dispatch) => {
    let user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)
    if (user) {
        dispatch({ type: actionType.EMP_LOGIN, payload: { ...JSON.parse(user), authorized: true } })
    }
}
