import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

// app styles


import 'bootstrap-css-only/css/bootstrap.min.css'
import './assets/styles/Antd/antd.cleanui.scss'
import './assets/styles/CleanUI/cleanui.scss';
// import './assets/styles/Bootstrap/bootstrap.cleanui.scss';
// import './assets/styles/Chartist/chartist.cleanui.scss';
import './assets/styles/Nprogress/nprogress.cleanui.scss';
import './assets/styles/Rcdrawer/rcdrawer.cleanui.scss';
// import 'c3/c3.min.css';
import '@ant-design/compatible/assets/index.css';
import './assets/css/tailwind.css'
import './global.scss'
import { App } from './App'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e6471d25c2084a6893ea93cb6088ec28@o1173453.ingest.sentry.io/6352464",
  integrations: [new BrowserTracing()],
  // normalizeDepth: 7,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)

serviceWorker.register()
