export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const onPreview = (file) => {
    if (file.url) {
        window.open(file.url, "_blank")
    } else if (file.thumbUrl) {
        if (file.type == "image/png" || file.type == "image/jpeg") {
            let image = new Image();
            image.src = file.thumbUrl;

            let w = window.open("", "_blank");
            w.document.write(image.outerHTML);
        }
        else if (file.type == "application/pdf") {
            let pdfWindow = window.open("", "_blank")
            pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='" + file.thumbUrl + "'></iframe>"
            )
        }
    }
}