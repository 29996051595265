import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from '../components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login/index'
import MainLayout from './Main'
import { checkLoginData } from "../pages/user/login/redux/method"
import { notification } from 'antd'
import { verifyEmail } from '../pages/user/login/redux/method'
import qs from 'qs'
const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {

  constructor(props) {
    super(props)
    this.query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  }

  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  componentWillMount() {

    var query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    const { dispatch } = this.props
    if (query && query.emailVerification) {

      dispatch(verifyEmail(query.emailVerification))
      localStorage.removeItem(window.SITE_CONFIG.STORAGE.USER)
      localStorage.removeItem(window.SITE_CONFIG.STORAGE.TOKEN)
      dispatch({
        type: 'user/LOGOUT',
      })


      this.props.history.push('/user/login')
    }
    //  else if (Object.keys(this.props.user).length == 0 && localStorage.getItem(window.SITE_CONFIG.STORAGE.USER && !this.query.page)) {
    //   this.props.dispatch(checkLoginData())
    // }
    else if (this.props.user.authorized) {
      dispatch({
        type: 'user/LOGOUT',
      })
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLoginLayout = getLayout() === 'login'

    const BootstrappedLayout = () => {
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="EONbt | %s" title="" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
