export function getLeftMenuData() {
  let user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)
  if (user) {
    user = JSON.parse(user)
  }

  let adminMenu = [
    {
      title: 'Home',
      key: 'home',
      url: '/',
      icon: 'icmn icmn-home',
    },
    // {
    //   title: 'Products',
    //   key: 'products',
    //   url: '/product',
    //   icon: 'icmn icmn-codepen',
    // },
    // {
    //   title: 'Settings',
    //   key: 'settings',
    //   url: '/settings',
    //   icon: 'icmn icmn-cog',
    //   children: [
    //     {
    //       key: 'account',
    //       title: 'Account',
    //       url: '/account',
    //     },
    //     {
    //       key: 'members',
    //       title: 'Members',
    //       url: '/members',
    //     }
    //   ]
    // }
  ]


  return adminMenu
}
