import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { getRoutes } from "./routes"
import IndexLayout from './layouts'
import NotFoundPage from './pages/404'
import { connect } from 'react-redux'



class Router extends React.Component {
  render() {
    let webRoutes = [...getRoutes(this.props.user)]
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/5285258-e55c-bbbb-def-defaulteonmed" />} />
            {webRoutes.map((route) => {
              if (route.active != false)
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={route.path}
                    exact={route.exact}
                  />
                )
            })}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps)(Router)
