import React from 'react'
import Loadable from 'react-loadable'
import Loader from './components/LayoutComponents/Loader'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

export const getRoutes = (user) => {
  const routes = [
    {
      path: '/user/login',
      component: loadable(() => import('./pages/user/login/loginPage')),
      exact: true,
    },

    {
      path: '/user/verifyEmail',
      component: loadable(() => import('./pages/user/register/verifyEmail.jsx')),
      exact: true,
    },
    // {
    //     path: '/order/medicine',
    //     component: loadable(() => import('./pages/order/Medicines.jsx')),
    //     exact: true,
    // },
    {
      path: '/order/address/:id/:token/:userId',
      component: loadable(() => import('./pages/order/Address.jsx')),
      exact: true,
    },
    {
      path: '/order/orderPlaced',
      component: loadable(() => import('./pages/order/OrderPlaced.jsx')),
      exact: true,
    },
    // {
    //     path: '/user/forgot',
    //     component: loadable(() => import('./pages/user/forgot')),
    //     exact: true,
    // },
    {
      path: '/order/:id/:token',
      component: loadable(() => import('./pages/order/index.jsx')),
      exact: true,
    },
    {
      path: '/user/resetPassword',
      component: loadable(() => import('./pages/user/forgot/resetPassword.jsx')),
      exact: true,
    },
    {
      path: '/:id',
      component: loadable(() => import('./pages/home/index.jsx')),
      exact: true,
    },
    {
      path: '/consultation/:id/:patientid',
      component: loadable(() => import('./pages/home/consultation.jsx')),
      exact: true,
    },
    {
      path: '/payment/:id',
      component: loadable(() => import('./pages/home/paymentPage.jsx')),
      exact: true,
    },
    {
      path: '/payment/pay',
      component: loadable(() => import('./pages/home/paymentPage.jsx')),
      exact: true,
    },
    {
      path: '/prescription',
      component: loadable(() => import('./pages/home/prescription.jsx')),
      exact: true,
    },
  ]
  return routes
}
