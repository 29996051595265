import axios from 'axios'
import { notification } from 'antd'
const instance = axios.create({
    baseURL: window.SITE_CONFIG.API_URL,
    //headers: { 'Authorization': 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZTI3ZWRkMTg0ZmE4MTBmZmYyMjFkM2UiLCJ3ZWJSb2xlIjoiU3VwZXIgQWRtaW4iLCJvcmdJZCI6IjVlMWI4MGY1YjZjOTg5MWNjNDMxZGZiOCIsImdyb3VwQWRtaW4iOltdLCJ1c2VySWQiOiI1ZTI3ZWRkMTg0ZmE4MTBmZmYyMjFkM2YiLCJleHAiOjE1ODQ4NjY3NTgsImlhdCI6MTU3OTY4Mjc1OH0.vNrYZZT4mTvbLM4xJiLwhpvvAoaBcrtFDdVmWGfEyrk' }
})
// instance.interceptors.request.use(function (config) {
//     const token = localStorage.getItem(window.SITE_CONFIG.STORAGE && window.SITE_CONFIG.STORAGE.TOKEN);


//     if (token && (!config.headers || !config.headers.Authorization)) {
//         config.headers.Authorization = "bearer " + token;
//     }

//     return config;
// });

export const callApi = (apiPromise) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await apiPromise())
        } catch (err) {
            const options = {
                message: 'Network request fail',
                description: err.message
            };
            if (err.response)
                options.description = err.response.data.error[0].msg
            notification.error(options);
            reject(err)
        }
    })
}

export default instance
